import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex-basis: 30%;
  margin: 10px;
  height: 70vh;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const CategoryName = styled.h1`
  color: white;
  margin-bottom: 10px;
`;

const Button = styled.button`
  border: none;
  padding: 20px;
  background-color: white;
  color: gray;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background-color: black;
    color: white;
  }
`;

const Category = ({ item }) => {
  return (
    <Container>
      <Image src={item.url} />
      <TextContainer>
        <CategoryName>{item.name}</CategoryName>
        <Button>Explore</Button>
      </TextContainer>
    </Container>
  );
};

export default Category;
