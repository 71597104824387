import React from "react";
import styled from "styled-components";
import Category from "./Category";

const Container = styled.div`
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;


const Categories = () => {
  const items = [
    {
      id: 1,
      name: "Jackets",
      url: "https://5.imimg.com/data5/YA/BE/MY-40698162/mens-jackets-500x500.jpg",
    },
    {
      id: 2,
      name: "T - shirt",
      url:"https://assets.myntassets.com/h_1440,q_100,w_1080/v1/assets/images/7851879/2019/11/23/47d4710b-2a79-408a-bfe6-3d4ac8beae501574504357602-Roadster-Men-Pack-of-6-T-shirts-6711574504350623-1.jpg"
    },
    {
      id: 3,
      name: "Jeans & pants",
      url:"https://cdn.britannica.com/74/190774-050-52CE5745/jeans-denim-pants-clothing.jpg?q=60"
    },
    {
      id: 4,
      name: "Dongri",
      url:"https://evilato.com/wp-content/uploads/2021/06/ms_xekpx_512_30804586.jpg"
    },
    {
      id: 5,
      name: "Accessories",
      url:"https://www.collegefashion.net/wp-content/uploads/2019/01/maxi-dress-jean-jacket-outfit.jpg"
    },
  ];
  return (
    <Container>
      {items.map((it) => (
        <Category item={it} key={it.id}/>
      ))}
    </Container>
  );
};

export default Categories;
