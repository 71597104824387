import { FavoriteBorderOutlined, SearchOutlined, ShoppingCartOutlined } from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    flex:1;
    margin: 5px;
    min-width: 280px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: relative;
`

const Circle = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  position: absolute;
  background-color: red;
`

const Image = styled.img`
  height: 75%;
  width: 80%;
  z-index: 2;
`
const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  opacity: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  &:hover{
    background-color: rgba(0,0,0,0.3);
    opacity: 1;
    cursor: pointer;
  }
`
  

const Icon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: gray;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  &:hover{
    background-color: ${props=>props.color};
    transform: scale(1.1);
  }
`

const Product = ({item,key}) => {
  return (
    <Container>
      <Circle />
      <Image src={item.image} />
      <InfoContainer>
        <Icon color="blue">
          <ShoppingCartOutlined />
        </Icon>
        <Icon color="green">
          <SearchOutlined />
        </Icon>
        <Icon color="red">
          <FavoriteBorderOutlined />
        </Icon>
      </InfoContainer>
    </Container>
  )
}

export default Product
