import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register"
import Login from "./pages/Login"
import Product from "./pages/Product";
import Cart from "./pages/Cart";

const App = () => {
  return <Cart/>
};

export default App;