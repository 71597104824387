import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
const Container = styled.div`
background-color: pink;
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: none;
  position: relative;
  overflow: hidden;
`;

const Arrow = styled.div`
  border: 2px solid black;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  cursor: pointer;
  opacity: 0.7;
  z-index: 2;
`;

const Slide = styled.div`
  display: flex;
  height: 100%;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.pageIndex * -100}vw);
`;

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
`;

const ImgContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  margin: auto;
  height: 85%;
  width: 75%;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 50px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 25px;
`;

const Desc = styled.p`
  margin: 50px 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  width: 90%;
`;

const Bttn = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: black;
    color: white;
  }
`;

const Slider = () => {
  const [products, setProducts] = useState([]);
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    const interval = setTimeout(() => {
      setPageIndex(pageIndex < products.length - 1 ? pageIndex + 1 : 0);
    }, 3000);
    fetch("https://fakestoreapi.com/products")
      .then((res) => res.json())
      .then((json) => setProducts(json));
    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      setPageIndex(pageIndex < products.length - 1 ? pageIndex + 1 : 0);
    }, 3000);
    return () => clearTimeout(interval);
  }, [pageIndex]);

  const handleArrowClick = (direction) => {
    if (direction === "left")
      setPageIndex(pageIndex > 0 ? pageIndex - 1 : products.length - 1);
    else setPageIndex(pageIndex < products.length - 1 ? pageIndex + 1 : 0);
    console.log(pageIndex);
  };

  return (
    <div>
      <Container>
        <Arrow direction="left" onClick={() => handleArrowClick("left")}>
          <ArrowLeftOutlined color="primary" />
        </Arrow>
        <Slide pageIndex={pageIndex}>
          {products.map((data) => {
            return (
              <Wrapper key={data.id}>
                <ImgContainer>
                  <Image src={data.image}></Image>
                </ImgContainer>
                <InfoContainer>
                  <Title>{data.title}</Title>
                  <Desc>{data.description}</Desc>
                  <Bttn>Shop Now</Bttn>
                </InfoContainer>
              </Wrapper>
            );
          })}
        </Slide>
        <Arrow direction="right" onClick={() => handleArrowClick("right")}>
          <ArrowRightOutlined color="primary" />
        </Arrow>
      </Container>
    </div>
  );
};

export default Slider;
